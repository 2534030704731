








































import { Component, Prop } from 'vue-property-decorator';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import Article from '@/models/graphql/Article';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import { namespace } from 'vuex-class';
import Order from '@/models/graphql/Order';
import OrderStatus from '@/utils/enums/OrderStatus';
import WizardValidationRuleType from '@/utils/types/WizardValidationRuleType';
import WizardWidgetWrapper from '@/components/wrappers/WizardWidgetWrapper';
import ClientStorage from '@/utils/ClientStore';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import WizardStepsEnum from '@/utils/enums/WizardStepsEnum';

const orderStore = namespace('OrderStore');

@Component({
  components: { ButtonComponent },
})
export default class WizardLegalValidationWidget
  extends mixins(VueBaseWidget, VueRegisterStoreWidget, WizardWidgetWrapper) {
  @Prop({ required: false, default: null })
  private readonly displayAsLink!: boolean;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Article[];

  @orderStore.State
  private stateOrder!: Order;

  private OrderStatus = OrderStatus;

  private get article(): Article | null {
    return this.data && this.data.length > 0 ? this.data[0] : null;
  }

  // eslint-disable-next-line class-methods-use-this
  private agreed(): boolean {
    const agreement = parseInt(
      ClientStorage.getItem(WizardStepsEnum.TERMS_AND_CONDITIONS) || '0', 10,
    );
    const wizard = parseInt(ClientStorage.getItem(WizardStepsEnum.VISIT_WIZARD) || '0', 10);
    return agreement > wizard;
  }

  created(): void {
    if (this.payload
      && 'article' in this.payload
      && this.payload.article) {
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'article',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.ARTICLE_FILTER,
            value: {
              id: this.payload.article,
              schemaCode: '%community%',
            },
          },
          orderBy: {
            type: GqlEntityOrderingType.ARTICLE_ORDERING,
            value: ['displaySequence_asc'],
          },
        }),
        fragmentName: 'articleBaseFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing category in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
    this.addValidator();
  }

  validationRule(): WizardValidationRuleType {
    return (from?: number, to?: number, steps?: Array<UiPageWidget>) => {
      if (!steps || !to) return false;

      const stepPayload = JSON.parse(steps[to - 1].payload || '{}');
      const stepNavigationCode = stepPayload.navigationCode;
      if ([WizardStepsEnum.CHECKOUT, WizardStepsEnum.CONFIRMATION].includes(stepNavigationCode)) {
        return true;
      }

      if (!this.agreed() && stepNavigationCode !== WizardStepsEnum.TERMS_AND_CONDITIONS) {
        this.setStep(WizardStepsEnum.TERMS_AND_CONDITIONS);
        return false;
      }

      if (this.agreed() && stepNavigationCode === WizardStepsEnum.TERMS_AND_CONDITIONS) {
        this.nextStep();
        return false;
      }
      return this.agreed() && stepNavigationCode !== WizardStepsEnum.TERMS_AND_CONDITIONS;
    };
  }

  protected nextStep(): void {
    ClientStorage.setItem(WizardStepsEnum.TERMS_AND_CONDITIONS, String(DateTimeHelper.currentTimestamp));
    this.$store.dispatch(`${this.wizardStoreName.value}/nextStep`);
  }

  private navigateBack(): void {
    if (this.stateOrder
      && this.$route.params
      && 'companyId' in this.$route.params
      && this.$route.params.companyId) {
      if (this.stateOrder.status === OrderStatus.NEW_PENDING) {
        this.$router.push({
          name: 'company-cms-cart',
          params: { companyId: this.$route.params.companyId },
        });
      } else {
        this.$router.push({
          name: 'company-cms-order',
          params: { companyId: this.$route.params.companyId },
        });
      }
    }
  }
}
